exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artworks-js": () => import("./../../../src/pages/artworks.js" /* webpackChunkName: "component---src-pages-artworks-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interviews-js": () => import("./../../../src/pages/interviews.js" /* webpackChunkName: "component---src-pages-interviews-js" */),
  "component---src-pages-screening-and-discussion-js": () => import("./../../../src/pages/screening-and-discussion.js" /* webpackChunkName: "component---src-pages-screening-and-discussion-js" */),
  "component---src-pages-worklab-js": () => import("./../../../src/pages/worklab.js" /* webpackChunkName: "component---src-pages-worklab-js" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-afro-cyber-resistance-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/afro-cyber-resistance.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-afro-cyber-resistance-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-all-what-is-somehow-useful-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/all-what-is-somehow-useful.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-all-what-is-somehow-useful-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-cativism-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/cativism.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-cativism-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-desert-of-errors-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/desert-of-errors.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-desert-of-errors-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-fango-cam-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/fango-cam.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-fango-cam-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-hyperconnected-past-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/hyperconnected-past.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-hyperconnected-past-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-insight-aaron-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/insight-aaron.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-insight-aaron-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-paketown-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/paketown.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-paketown-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-refusing-to-meet-your-eye-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/refusing-to-meet-your-eye.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-refusing-to-meet-your-eye-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-the-annunciation-of-the-digital-angels-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/the-annunciation-of-the-digital-angels.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-the-annunciation-of-the-digital-angels-mdx" */),
  "component---src-templates-artworks-template-js-content-file-path-src-artworks-why-is-archiving-so-unsexy-again-mdx": () => import("./../../../src/templates/artworks-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/artworks/why-is-archiving-so-unsexy-again.mdx" /* webpackChunkName: "component---src-templates-artworks-template-js-content-file-path-src-artworks-why-is-archiving-so-unsexy-again-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-evelyn-tschernko-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/evelyn-tschernko.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-evelyn-tschernko-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-huda-takriti-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/huda-takriti.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-huda-takriti-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-karl-voit-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/karl-voit.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-karl-voit-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-leo-caobelli-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/leo-caobelli.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-leo-caobelli-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-leonhard-rabensteiner-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/leonhard-rabensteiner.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-leonhard-rabensteiner-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-martin-nadal-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/martin-nadal.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-martin-nadal-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-miguel-rangil-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/miguel-rangil.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-miguel-rangil-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-nestor-sire-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/nestor-sire.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-nestor-sire-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-nicole-kouts-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/nicole-kouts.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-nicole-kouts-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-pim-zwier-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/pim-zwier.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-pim-zwier-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-ricardo-gines-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/ricardo-gines.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-ricardo-gines-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-schuberttheater-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/schuberttheater.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-schuberttheater-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-sebastian-palacios-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/sebastian-palacios.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-sebastian-palacios-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-steffen-koehn-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/steffen-koehn.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-steffen-koehn-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-tabita-rezaire-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/tabita-rezaire.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-tabita-rezaire-mdx" */),
  "component---src-templates-contributors-template-js-content-file-path-src-contributors-wouter-moraal-mdx": () => import("./../../../src/templates/contributors-template.js?__contentFilePath=C:/Users/Juan P/projects/unarchive/unarchive/src/contributors/wouter-moraal.mdx" /* webpackChunkName: "component---src-templates-contributors-template-js-content-file-path-src-contributors-wouter-moraal-mdx" */)
}

